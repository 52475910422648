// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  teacher: icon("ic_teacher"),
  student: icon("ic_student"),
  courses: icon("ic_courses"),

  blog: icon("ic_blog"),
  cart: icon("ic_cart"),
  chat: icon("ic_chat"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard_new"),
  policy: icon("ic_policy"),
  inventory: icon("ic_inventory"),
  setting: icon("ic_setting"),
  resources: icon("ic_resources"),
};

const navConfig = [
  // ----------------------------------------------------------------------
  // MANAGEMENT
  {
    items: [
      {
        title: "home",
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
      },
    ],
  },
];

// ============SX MOdules==========
if (window.checkPermission("home.student.page"))
  navConfig[0].items.push({
    title: "student",
    path: PATH_DASHBOARD.student.root,
    icon: ICONS.student,
  });

if (
  window.checkPermission("home.teacher.page") ||
  window.checkPermission("home.teacher.add")
)
  navConfig[0].items.push({
    title: "teachers",
    path: PATH_DASHBOARD.teacher.root,
    icon: ICONS.teacher,
  });

if (
  window.checkPermission("home.blueprint.page") ||
  window.checkPermission("home.blueprint.add")
)
  navConfig[0].items.push({
    title: "blueprint",
    path: PATH_DASHBOARD.blueprint.root,
    icon: ICONS.menuItem,
  });

if (
  window.checkPermission("home.admin.page") ||
  window.checkPermission("home.admin.add")
)
  navConfig[0].items.push({
    title: "admin",
    path: PATH_DASHBOARD.admin.root,
    icon: ICONS.user,
  });

export default navConfig;
