import { useEffect } from "react";
import { useNavigate } from "react-router";
import { logoutUser } from "src/auth/utils";

function WindowConfig() {
  const navigate = useNavigate();
  window.redirect = (path) => {
    setTimeout(() => {
      navigate(path);
    }, 1);
  };
  const checkForInactivity = () => {
    const expireTime = localStorage.getItem("expireTime");

    if (expireTime < Date.now()) {
      logoutUser();
    }
  };

  const updateExpireTime = () => {
    const expireTime = Date.now() + 600000;
    localStorage.setItem("expireTime", expireTime);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateExpireTime();

    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    return () => {
      window.addEventListener("click", updateExpireTime);
      window.addEventListener("keypress", updateExpireTime);
      window.addEventListener("scroll", updateExpireTime);
      window.addEventListener("mousemove", updateExpireTime);
    };
  });
}

export default WindowConfig;
