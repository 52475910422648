// @mui
import { ListItemButton, ListItemIcon, ListSubheader } from "@mui/material";
import { styled } from "@mui/material/styles";
// config
import { ICON, NAV } from "../../../config-global";

// ----------------------------------------------------------------------

export const StyledItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "caption",
})(({ active, disabled, depth, caption, theme }) => {
  const isLight = theme.palette.mode === "light";

  const subItem = depth !== 1;

  const activeStyle = {
    color: "#F36E21",
    backgroundColor: "#fff",
    borderColor: "#F36E21",
  };

  const activeSubStyle = {
    color: "#F36E21",
    backgroundColor: "#fff",
    borderColor: "#F36E21",
  };

  return {
    position: "relative",
    textTransform: "capitalize",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    marginBottom: "15px",
    borderRadius: "4px",
    height: NAV.H_DASHBOARD_ITEM,

    color: "#f36e21",
    backgroundColor: "#fff",
    border: "1px solid #fff",

    // Sub item
    ...(subItem && {
      height: NAV.H_DASHBOARD_ITEM_SUB,
      ...(depth > 2 && {
        paddingLeft: theme.spacing(depth),
      }),
      ...(caption && {
        height: NAV.H_DASHBOARD_ITEM,
      }),
    }),
    // Active item
    ...(active && {
      ...activeStyle,
      "&:hover": {
        ...activeStyle,
      },
    }),

    // Active sub item
    ...(subItem &&
      active && {
        ...activeSubStyle,
        "&:hover": {
          ...activeSubStyle,
        },
      }),
    // Disabled
    ...(disabled && {
      "&.Mui-disabled": {
        opacity: 0.64,
      },
    }),
  };
});

// ----------------------------------------------------------------------

export const StyledIcon = styled(ListItemIcon)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: ICON.NAV_ITEM,
  height: ICON.NAV_ITEM,
});

// ----------------------------------------------------------------------

export const StyledDotIcon = styled("span", {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active, theme }) => ({
  width: 4,
  height: 4,
  borderRadius: "50%",
  backgroundColor: theme.palette.text.disabled,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shorter,
  }),
  ...(active && {
    transform: "scale(2)",
    backgroundColor: theme.palette.primary.main,
  }),
}));

// ----------------------------------------------------------------------

export const StyledSubheader = styled(ListSubheader)(({ theme }) => ({
  ...theme.typography.overline,
  fontSize: 11,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
}));
