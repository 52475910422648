// routes
import RolePermissions from "src/config/RolePermissions";
import { PATH_AUTH } from "../routes/paths";
// utils
import axios from "../utils/axios";

// ----------------------------------------------------------------------

export function jwtDecode(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert("Token expired");

    localStorage.removeItem("accessToken");

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    localStorage.removeItem("accessToken");

    delete axios.defaults.headers.common.Authorization;
  }
};

// Admin functions
export const isUserLoggedIn = () => {
  return Boolean(localStorage.getItem("adminAccessToken"));
};

export const userId = () => {
  let user = getLoggedInUser();
  return user?.id ?? undefined;
};

export const userName = () => {
  let user = getLoggedInUser();
  return user?.name ?? undefined;
};

export const userEmail = () => {
  let user = getLoggedInUser();
  return user?.email ?? undefined;
};

export const getLoggedInUser = () => {
  if (isUserLoggedIn()) {
    let useDetailsJsonString = localStorage.getItem("adminDetails") ?? "{}",
      user = {};
    try {
      user = JSON.parse(useDetailsJsonString);
    } catch (error) {}
    return user;
  }
};

export const saveUser = (data) => {
  if (data.token) {
    localStorage.setItem("adminAccessToken", data.token);
    localStorage.setItem("adminDetails", JSON.stringify(data));
  }
  return Boolean(localStorage.getItem("adminAccessToken"));
};

export const logoutUser = () => {
  localStorage.clear("adminAccessToken");
  window.location.reload();
};

const checkPermission = (permission) => {
  if (permission === "") return false;

  const userRole = getLoggedInUser()?.role;

  const permissions = RolePermissions[userRole] ?? [];

  return permissions.includes(permission);
};

window.checkPermission = checkPermission;
