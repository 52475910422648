import { lazy, Loadable } from "src/routes/utils";

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(
  lazy(() => import("src/pages/auth/LoginPage"))
);
export const RegisterPage = Loadable(
  lazy(() => import("src/pages/auth/RegisterPage"))
);
export const VerifyCodePage = Loadable(
  lazy(() => import("src/pages/auth/VerifyCodePage"))
);
export const NewPasswordPage = Loadable(
  lazy(() => import("src/pages/auth/NewPasswordPage"))
);
export const ChangePasswordPage = Loadable(
  lazy(() => import("src/pages/auth/ChangePasswordPage"))
);
export const ResetPasswordPage = Loadable(
  lazy(() => import("src/pages/auth/ResetPasswordPage"))
);
// =====SX Dashboard======
export const TeacherListPage = Loadable(
  lazy(() => import("src/pages/teachers/TeacherListPage"))
);
export const TeacherCreatePage = Loadable(
  lazy(() => import("src/pages/teachers/AddTeacherDialog"))
);
export const AdminListPage = Loadable(
  lazy(() => import("src/pages/admins/AdminListPage"))
);
export const StudentListPage = Loadable(
  lazy(() => import("src/pages/student/StudentListPage"))
);
export const StudentDetailsPage = Loadable(
  lazy(() => import("src/pages/student/StudentDetailsPage"))
);
export const BlueprintListPage = Loadable(
  lazy(() => import("src/pages/blueprint/BlueprintListPage"))
);
export const ProgramListPage = Loadable(
  lazy(() => import("src/pages/course/ProgramListPage"))
);

// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(
  lazy(() => import("src/pages/dashboard/GeneralAppPage"))
);
export const GeneralEcommercePage = Loadable(
  lazy(() => import("src/pages/dashboard/GeneralEcommercePage"))
);
export const GeneralAnalyticsPage = Loadable(
  lazy(() => import("src/pages/dashboard/GeneralAnalyticsPage"))
);
export const GeneralBankingPage = Loadable(
  lazy(() => import("src/pages/dashboard/GeneralBankingPage"))
);
export const GeneralBookingPage = Loadable(
  lazy(() => import("src/pages/dashboard/GeneralBookingPage"))
);
export const GeneralFilePage = Loadable(
  lazy(() => import("src/pages/dashboard/GeneralFilePage"))
);

// DASHBOARD: REPORT
export const ReportListPage = Loadable(
  lazy(() => import("src/pages/student/Learning/PublicLearningPage"))
);

export const PublicLearningPage = Loadable(
  lazy(() => import("src/pages/student/Learning/PublicLearningPage"))
);

export const PublicAssessmentPage = Loadable(
  lazy(() => import("src/pages/student/Assessment/PublicAssessmentPage"))
);

export const ReportDetailsPage = Loadable(
  lazy(() => import("src/pages/dashboard/report/ReportDetailsPage"))
);

// DASHBOARD: USER
export const UserProfilePage = Loadable(
  lazy(() => import("src/pages/dashboard/user/UserProfilePage"))
);
export const UserCardsPage = Loadable(
  lazy(() => import("src/pages/dashboard/user/UserCardsPage"))
);
export const UserListPage = Loadable(
  lazy(() => import("src/pages/dashboard/user/UserListPage"))
);
// export const UserAccountPage = Loadable(
//   lazy(() => import("src/pages/dashboard/user/UserAccountPage"))
// );
export const UserCreatePage = Loadable(
  lazy(() => import("src/pages/dashboard/user/UserCreatePage"))
);
export const UserViewPage = Loadable(
  lazy(() => import("src/pages/dashboard/user/UserViewPage"))
);
export const UserEditPage = Loadable(
  lazy(() => import("src/pages/dashboard/user/UserEditPage"))
);

// DASHBOARD: ROLE
export const RoleProfilePage = Loadable(
  lazy(() => import("src/pages/dashboard/role/RoleProfilePage"))
);
export const RoleCardsPage = Loadable(
  lazy(() => import("src/pages/dashboard/role/RoleCardsPage"))
);
export const RoleListPage = Loadable(
  lazy(() => import("src/pages/dashboard/role/RoleListPage"))
);
export const RoleAccountPage = Loadable(
  lazy(() => import("src/pages/dashboard/role/RoleAccountPage"))
);
export const RoleCreatePage = Loadable(
  lazy(() => import("src/pages/dashboard/role/RoleCreatePage"))
);
export const RoleViewPage = Loadable(
  lazy(() => import("src/pages/dashboard/role/RoleViewPage"))
);

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(
  lazy(() => import("src/pages/dashboard/PermissionDeniedPage"))
);

// MAIN
export const Page500 = Loadable(lazy(() => import("src/pages/Page500")));
export const Page403 = Loadable(lazy(() => import("src/pages/Page403")));
export const Page404 = Loadable(lazy(() => import("src/pages/Page404")));
export const HomePage = Loadable(lazy(() => import("src/pages/HomePage")));
export const FaqsPage = Loadable(lazy(() => import("src/pages/FaqsPage")));
export const AboutPage = Loadable(lazy(() => import("src/pages/AboutPage")));
// export const Contact = Loadable(lazy(() => import("src/pages/ContactPage")));

export const PricingPage = Loadable(
  lazy(() => import("src/pages/PricingPage"))
);
export const PaymentPage = Loadable(
  lazy(() => import("src/pages/PaymentPage"))
);
export const ComingSoonPage = Loadable(
  lazy(() => import("src/pages/ComingSoonPage"))
);
export const MaintenancePage = Loadable(
  lazy(() => import("src/pages/MaintenancePage"))
);
